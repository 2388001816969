/* line 32, scss/80-themes/Saunders/60-components/home-values-map.scss */
.c-home-values-map {
	height: 600px;
}

/* line 36, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__map-legend-container {
	padding: 15px 20px;
	background: rgb(var(--color-global-widget));
}

@media only screen and (max-width: 29.999em) {
	/* line 36, scss/80-themes/Saunders/60-components/home-values-map.scss */
	.home-values__map-legend-container {
		padding: 15px 0;
	}
}

/* line 44, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__bubble {
	max-width: 300px;
	max-height: 560px;
	min-height: 300px;
	background: rgb(var(--color-global-widget));
	box-sizing: border-box;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	/* line 44, scss/80-themes/Saunders/60-components/home-values-map.scss */
	.home-values__bubble {
		height: 560px;
	}
}

@media only screen and (max-width: 29.999em) {
	/* line 44, scss/80-themes/Saunders/60-components/home-values-map.scss */
	.home-values__bubble {
		max-width: 100%;
		width: 100%;
	}
}

/* line 60, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__bubble .price__value {
	color: inherit;
	font-size: inherit;
	font-family: inherit;
	font-display: swap;
}

/* line 67, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__bubble .info-bubble__action-close {
	right: 0;
	display: block;
	position: absolute;
	top: 0;
	font-size: 1.5em;
	line-height: normal;
	padding: 10px;
	background-color: rgb(var(--color-global-widget));
	color: #709fb3;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .home-values__bubble .info-bubble__action-close {
	right: auto;
	left: 0;
}

/* line 80, scss/80-themes/Saunders/60-components/home-values-map.scss */
.marketdata-detailscontainer {
	right: 20%;
	position: absolute;
	top: 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .marketdata-detailscontainer {
	right: auto;
	left: 20%;
}

@media only screen and (max-width: 29.999em) {
	/* line 80, scss/80-themes/Saunders/60-components/home-values-map.scss */
	.marketdata-detailscontainer {
		right: auto;
		position: relative;
		top: auto;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .marketdata-detailscontainer {
		right: auto;
		left: auto;
	}
}

/* line 92, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__address-primary {
	color: #324B71;
	font-size: 19px;
}

/* line 97, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__address-secondary {
	color: #656565;
	font-size: 12px;
	padding-bottom: 5px;
}

/* line 103, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__property-price {
	color: #324B71;
	font-size: 16px;
	padding: 2px 0;
}

@media only screen and (max-width: 47.999em) {
	/* line 108, scss/80-themes/Saunders/60-components/home-values-map.scss */
	.home-values__property-price + .home-values__property-price--estimated {
		/* Force override the in-grid value for second item */
		/* TODO Responsive: Make aternative solution with higher specificity */
		padding-left: 20px !important;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .home-values__property-price + .home-values__property-price--estimated {
		padding-left: initial !important;
		padding-right: 20px !important;
	}
	/* line 114, scss/80-themes/Saunders/60-components/home-values-map.scss */
	.home-values__property-price + .home-values__property-price--estimated .price,
	.home-values__property-price + .home-values__property-price--estimated .home-values__property-price-description {
		margin-left: 5px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .home-values__property-price + .home-values__property-price--estimated .price, .is-rtl
	.home-values__property-price + .home-values__property-price--estimated .home-values__property-price-description {
		margin-left: initial;
		margin-right: 5px;
	}
}

@media only screen and (max-width: 47.999em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .home-values__property-price + .home-values__property-price--estimated .price, .is-rtl
	.home-values__property-price + .home-values__property-price--estimated .home-values__property-price-description {
		margin-left: 0;
	}
}

/* line 121, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__property-price .price__currency {
	line-height: 0.7em;
	font-size: 1em;
}

/* line 128, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__property-price-description {
	color: #656565;
	font-size: 12px;
}

/* line 134, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__details-header--separator {
	margin: 5px 0;
	border-bottom: 1px solid #fff;
}

/* line 140, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__property-count {
	color: #537DBF;
	text-transform: uppercase;
	font-size: 16px;
	padding: 0 20px 5px 20px;
}

/* line 146, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__property-count .icon {
	color: #666;
}

/* line 150, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__property-count .list-inline__item {
	padding-right: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .home-values__property-count .list-inline__item {
	padding-right: initial;
	padding-left: 5px;
}

/* line 157, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__property-detail-holder > :not(.selected) {
	display: none;
}

/* line 164, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__property-detail-holder .home-values__property-details-header {
	padding-top: 10px;
}

/* line 167, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__property-detail-holder .home-values__property-details-header .order-report {
	margin: 10px 0;
}

/* line 172, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__property-detail-holder .home-values__property-details-pricepersqft, .home-values__property-detail-holder .home-values__property-details-builtyear, .home-values__property-detail-holder .home-values__property-details-type {
	padding: 2px 0;
}

/* line 178, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__property {
	padding: 10px 20px;
	color: #000;
	font-size: 14px;
}

/* line 183, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__property.selected {
	background-color: #537DBF;
	color: #fff;
}

/* line 188, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__property:hover {
	cursor: pointer;
}

/* line 193, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__property .list-wide__item.beds, .home-values__property .list-wide__item.area {
	width: 25%;
}

/* line 199, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__map-legend {
	line-height: 1;
}

/* line 203, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__map-legend-item {
	margin-left: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .home-values__map-legend-item {
	margin-left: initial;
	margin-right: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .home-values__map-legend-item {
		margin-left: 0;
	}
}

/* line 207, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__map-legend-box {
	margin-right: 5px;
	width: 12px;
	height: 12px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .home-values__map-legend-box {
	margin-right: initial;
	margin-left: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .home-values__map-legend-box {
		margin-right: 0;
	}
}

/* line 212, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__map-legend-box--sale {
	background-color: #748c30;
}

/* line 216, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__map-legend-box--estimated {
	background-color: rgb(var(--color-global-primary));
}

/* line 220, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__map-legend-box--recently-sold {
	background-color: #b14232;
}

/* line 226, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__map-legend-value {
	margin-right: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .home-values__map-legend-value {
	margin-right: initial;
	margin-left: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .home-values__map-legend-value {
		margin-right: 0;
	}
}

/* line 230, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__map-legend-value,
.home-values__map-legend-box {
	display: inline-block;
	vertical-align: middle;
}

/* line 237, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__marker-sale,
.home-values__marker-sold,
.home-values__marker-recently-sold,
.home-values__marker-estimated {
	border-radius: 5px;
	color: rgb(var(--color-global-positive));
	font-size: 12px;
	padding: 5px 10px;
	cursor: pointer;
	box-sizing: border-box;
	border: 1px solid rgb(var(--color-global-positive));
	position: relative;
}

/* line 250, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__marker-sale:after,
.home-values__marker-sold:after,
.home-values__marker-recently-sold:after,
.home-values__marker-estimated:after {
	transform-origin: left top;
	transform: translate(-50%, 0%) rotate(-45deg);
	left: 50%;
	box-shadow: -1px 1px 1px 0 rgb(var(--color-global-positive));
	content: '';
	position: absolute;
	bottom: -9px;
	width: 0;
	height: 0;
	box-sizing: border-box;
	border: 5px solid black;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .home-values__marker-sale:after, .is-rtl
.home-values__marker-sold:after, .is-rtl
.home-values__marker-recently-sold:after, .is-rtl
.home-values__marker-estimated:after {
	left: auto;
	right: 50%;
}

/* line 267, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__marker-estimated {
	background-color: rgb(var(--color-global-primary));
}

/* line 270, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__marker-estimated:after {
	border-color: transparent transparent rgb(var(--color-global-primary)) rgb(var(--color-global-primary));
}

/* line 274, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__marker-estimated:hover {
	background-color: rgb(var(--color-global-primary));
}

/* line 277, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__marker-estimated:hover:after {
	border-color: transparent transparent rgb(var(--color-global-primary)) rgb(var(--color-global-primary));
}

/* line 282, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__marker-estimated.marker-status-highlight {
	background-color: #547dbf;
}

/* line 285, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__marker-estimated.marker-status-highlight:after {
	border-color: transparent transparent #547dbf #547dbf;
}

/* line 291, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__marker-sale {
	background-color: #748c30;
}

/* line 295, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__marker-recently-sold,
.home-values__marker-sold {
	background-color: #b14232;
}

/* line 299, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__marker-recently-sold:after,
.home-values__marker-sold:after {
	border-color: transparent transparent #b14232 #b14232;
}

/* line 303, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__marker-recently-sold:hover,
.home-values__marker-sold:hover {
	background-color: #b14232;
}

/* line 306, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__marker-recently-sold:hover:after,
.home-values__marker-sold:hover:after {
	border-color: transparent transparent #b14232 #b14232;
}

/* line 311, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__marker-recently-sold.marker-status-highlight,
.home-values__marker-sold.marker-status-highlight {
	background-color: #b14232;
}

/* line 314, scss/80-themes/Saunders/60-components/home-values-map.scss */
.home-values__marker-recently-sold.marker-status-highlight:after,
.home-values__marker-sold.marker-status-highlight:after {
	border-color: transparent transparent #b14232 #b14232;
}

/*# sourceMappingURL=../../../../true */